<template>
  <AgappsLanding />
</template>

<script>
import AgappsLanding from './components/AgappsLanding.vue'

export default {
  name: 'App',
  components: {
    AgappsLanding
  }
}
</script>